export default [
  {
    approvedDate: "8/15/1998 8:27:45 AM",
    id: "cbb7ca24-0f50-8bf8-0d1f-1d98fc77abe1",
    name: "Airtel Networks Kenya",
    clientType: "Mobile Network",
    attachments: [
      {
        id: "gvwgtsrzdv",
        url: "https://myip/kIaaHQslFugbPzC.com",
        idLegalDocumentType: "clutyymdda"
      },
      {
        id: "jcjzstciia",
        url: "https://myip/MEWlijKMxBluGTJ.com",
        idLegalDocumentType: "rgjqojzoaj"
      }
    ],
    contactPerson: "Angie Macejkovic",
    contactPhone: "940-392-8656 x145",
    contactEmail: "ldZKlJWjSF@testnms.com"
  },
  {
    approvedDate: "3/19/2002 8:08:53 PM",
    id: "9496778f-328b-cec2-7974-e0dfbdb4b747",
    name: "Airtel Networks Kenya",
    clientType: "Mobile Virtual Network",
    attachments: [
      {
        id: "nwvrtnxetf",
        url: "https://myip/wLnPbjjLmIKFZVx.com",
        idLegalDocumentType: "vetjvostma"
      },
      {
        id: "ttldulnznd",
        url: "https://myip/tuLrEUqoUQAPTdK.com",
        idLegalDocumentType: "ivoofdktcr"
      }
    ],
    contactPerson: "Raymond Torp",
    contactPhone: "(355) 786-4983 x875",
    contactEmail: "lFXemtJGay@testnms.com"
  }
];
