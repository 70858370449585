import {createSlice} from "@reduxjs/toolkit";

const initialClientManagementsState = {
  listLoading: false,
  listLoadingPending: false,
  totalCountPending: 0,
  listLoadingRejected: false,
  totalCountRejected: 0,
  listLoadingApproved: false,
  totalCountApproved: 0,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  loadData:null,
  fileName:[],
  clientManagementsPending:null,
  clientManagementsRejected:null,
  clientManagementsApproved:null,
  clientManagementForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const clientManagementsSlice = createSlice({
  name: "clientManagements",
  initialState: initialClientManagementsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    clientManagementsLoadedData: (state, action) => {
      state.error = null;
      state.loadData = action.payload.loadData;
      state.actionsLoading = false;
    },
    attachmentDeleted: (state, action) => {
      state.error = null;
      state.fileName = state.fileName.filter(el => el.idLegalDocumentType !== action.payload.idLegalDocumentType.idLegalDocumentType);
      state.actionsLoading = false;
    },
    allAttachmentDeleted: (state, action) => {
      state.error = null;
      state.fileName = [];
      state.actionsLoading = false;
    },
    // getClientManagementById
    clientManagementFetched: (state, action) => {
      state.actionsLoading = false;
      state.clientManagementForEdit = action.payload.clientManagementForEdit && action.payload.clientManagementForEdit.data;
      state.error = null;
    },
    // findClientManagements
    clientManagementsPendingFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingPending = false;
      state.error = null;
      state.clientManagementsPending = list;
      state.totalCountPending = totalCount;
    },
    // findClientManagements
    clientManagementsRejectedFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingRejected = false;
      state.error = null;
      state.clientManagementsRejected = list;
      state.totalCountRejected = totalCount;
    },
    // findClientManagements
    clientManagementsApprovedFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingApproved = false;
      state.error = null;
      state.clientManagementsApproved = list;
      state.totalCountApproved = totalCount;
    },
    // createClientManagement
    clientManagementCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.clientManagement);
    },
    // updateClientManagement
    clientManagementUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.clientManagement.id) {
      //     return action.payload.clientManagement;
      //   }
      //   return entity;
      // });
    },
    // deleteClientManagement
    clientManagementDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteClientManagement
    attachmentUploaded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.fileName.push(action.payload.fileName)
    },
    // deleteClientManagements
    clientManagementsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    clientManagementApproved: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    clientManagementRejected: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // clientManagementsUpdateState
    clientManagementsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
