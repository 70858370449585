import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Encrypt } from "../../../../_components/Axios";
import { KTCookie } from "../../../../_metronic/_assets/js/components/cookie";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  username: "",
  password: "",
  idProvider: 0,
  socialToken: "string",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    username: Yup.string().trim()
      // .email("Wrong email format")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string().trim()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(
          values.username,
          Encrypt(values.password),
          // values.password,
          values.idProvider,
          values.socialToken
        )
          .then(({ data }) => {
            if (data.statusCode.code == 0) {
              disableLoading();
              props.login(data.sections, data.statusCode, data.user);
            } else {
              disableLoading();
              setSubmitting(false);
              setStatus(data.statusCode.message);
            }
            // disableLoading();

            // props.login(authToken);
          })
          .catch((error) => {
            //  console.log(error);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
          <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl("/media/logos/logo-dark.png")}
          />
        </Link>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework auth"
      >
        {
          KTCookie.getCookie("AD") == "true" &&
            <div className="mb-10 alert alert-custom alert-light-warning alert-dismissible">
              <div className="alert-text font-weight-bold">Access Denied</div>
            </div>
        }
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
          // <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
          //   <div className="alert-text ">
          //     Use account <strong>admin@demo.com</strong> and password{" "}
          //     <strong>demo</strong> to continue.
          //   </div>
          // </div>
        )}

        <h3 className="font-size-h3 text-white pb-2">Sign in</h3>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="User Name"
            type="username"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap mb-0 flex-column justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-white text-hover-secondary my-3 mr-2"
            id="kt_login_forgot"
          >
            Need help?
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Login</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>

        <div className="text-center flex-column-auto justify-content-center py-5 px-10">
          <span className="font-weight-bold text-white">
            Don't have an account?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2 text-white"
            id="kt_login_signup"
          >
            Register
          </Link>
        </div>
      </form>

      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
