export const Dashboard = "63f51b84-0aaa-4c41-a9c2-0c888266d730";
export const ClientManagement = "7195a906-e023-4aa6-8755-2729aa60bf02";
export const ApplicationManagementCAK = "094a6151-7603-4538-9eed-a3c3d9e12f6d";
export const ResourceManagementStockingResources = "c168e5d9-c887-4512-9d28-8c34ab57152c";
export const ResourceManagementReclaimingResources = "b9e1fba3-c954-41d0-b084-c62c52bea172";
export const ResourceManagementPendingRecallingResources = "50347b87-829b-4100-b51e-48d316b1c02f";
export const InterfaceReport = "3fb5cb72-0917-44a2-8f39-d776548ac99c";
export const ConfigurationResourcesType = "c5164af2-6ae8-4c35-b736-aec989771175";
export const ConfigurationResourcesSubtype = "cdb716cf-365a-4d4d-8d07-4a1e5a077395";
export const ConfigurationClientPermission = "b5dceb81-b97b-4b9d-935f-2a8e4aa16d39";
export const WebRole = "ed9aff40-1d5d-11ed-9289-005056b8db4f";
export const WebUsers = "d5cb6328-1d5d-11ed-9289-005056b8db4f";
export const Settings = "21b4f380-1a50-4d8e-9a89-821e764cb1b4";
export const CompanyProfile = "56fe74f4-a97a-4e70-bd11-ce1b2bc9d9da";
export const ApplicationManagementClient = "ae7757e6-8274-46e4-996d-9637ff47cc0e";
export const ResourceManagementRecallingResources = "f6a06145-9964-4c47-9c48-588609882db7";