import React, { useState ,useEffect} from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import { download, toastMe,Encrypt } from "../../../../_components/Axios";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const initialValues = {
  password: "",
  confirmPassword: "",
  idUser:  window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
};

function EnterNewPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const EnterNewPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both passwords need to be the same"
        ),
      })
      .required("Password is required"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: EnterNewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      resetPassword(
        // values.password,
        // values.confirmPassword,
        Encrypt(values.password),
        Encrypt(values.confirmPassword),
        values.idUser)
        .then((response) => {
          if(response.error) toastMe(true, response.message, "warning"); 
          else 
          {
            toastMe(true, response.data.statusCode.message, "success"); 
            setIsRequested(true);
          }
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus("Something Went Wrong!");
        });
    },
  });

  // useEffect(() => {    
  //   loadReCaptcha(CAPTCHA_PUBLIC_KEY);
  // },[]);


  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        
        <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                  <img
                    alt="Logo"
                    className="max-h-70px"
                    src={toAbsoluteUrl("/media/logos/logo-dark.png")}
                  />
                </Link>
        </div>
        {/* end::Head */}
          <div className="text-center mb-10 mb-lg-20">
            {/* <h3 className="font-size-h1">Forgotten Password ?</h3> */}
            <div className="text-muted font-weight-bold">
              Enter your new password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form auth fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid cust-input h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid cust-input h-auto py-5 px-6 ${getInputClasses(
              "confirmPassword"
            )}`}
            name="confirmPassword"
            {...formik.getFieldProps("confirmPassword")}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.confirmPassword}</div>
            </div>
          ) : null}
        </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-white font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-white font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(EnterNewPassword));
