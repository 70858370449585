import axios from "axios";
import { sendRequestDotNet } from "../../../../../_components/Axios";

export const CUSTOMERS_URL = "CompanyProfile";


export function getCompanyProfileById() {
  return sendRequestDotNet(`${CUSTOMERS_URL}/Get`,  {} );
}


// UPDATE => PUT: update the procuct on the server
export function updateCompanyProfile(companyProfile) {
  return sendRequestDotNet(`${CUSTOMERS_URL}/Update`,  companyProfile );
}
export function addCompanyProfile(companyProfile) {
  return sendRequestDotNet(`${CUSTOMERS_URL}/Add`,  companyProfile );
}

export function uploadAttachment(file) {
  return sendRequestDotNet(`${CUSTOMERS_URL}/UploadLegalDoc`,file);
}
// LoadData
export function companyProfilesLoadData() {
  return sendRequestDotNet(`${CUSTOMERS_URL}/LoadData`,{},{});
}