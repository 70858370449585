import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { ApplicationReportPage } from "./pages/ApplicationReportPage";
import { StockReportPage } from "./pages/StockReportPage";
import { useSelector, shallowEqual } from "react-redux";
import ErrorPage7 from "./modules/ErrorsExamples/ErrorPage7";
import {
  Dashboard,
  ClientManagement,
  ApplicationManagementCAK,
  ResourceManagementStockingResources,
  ResourceManagementReclaimingResources,
  ResourceManagementPendingRecallingResources,
  InterfaceReport,
  ConfigurationResourcesType,
  ConfigurationResourcesSubtype,
  ConfigurationClientPermission,
  WebRole,
  WebUsers,
  Settings,
  CompanyProfile,
  ApplicationManagementClient,
  ResourceManagementRecallingResources,
} from "./RoutesId";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const ClientPortalPage = lazy(() =>
  import("./modules/ClientPortal/pages/ClientPortalPage")
);
const ClientManagementPage = lazy(() =>
  import("./modules/ClientPortal/pages/ClientManagementPage")
);
const WebUserPage = lazy(() =>
  import("./modules/ClientPortal/pages/WebUserPage")
);
const WebRolePage = lazy(() =>
  import("./modules/ClientPortal/pages/WebRolePage")
);
const ResourceManagementPage = lazy(() =>
  import("./modules/ClientPortal/pages/ResourceManagementPage")
);
const ConfigurationPage = lazy(() =>
  import("./modules/ClientPortal/pages/ConfigurationPage")
);
const ApplicationManagementPage = lazy(() =>
  import("./modules/ClientPortal/pages/ApplicationManagementPage")
);
const SettingPage = lazy(() =>
  import("./modules/ClientPortal/pages/SettingPage")
);
const CompanyProfilePage = lazy(() =>
  import("./modules/ClientPortal/pages/CompanyProfilePage")
);
const IntroPage = lazy(() =>
  import("./modules/ClientPortal/pages/IntroPage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const { user } = useSelector(
    (state) => ({
      user: state.auth,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/introduction" />
        }
        <ContentRoute
          path="/dashboard"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                (user.user.utype == 1 &&
                  item.id == Dashboard &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1) ||
                (user.user.utype == 2 &&
                  item.id == Dashboard &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1)
            ) != -1
              ? DashboardPage
              : ErrorPage7
          }
        />
        <ContentRoute
          path="/interface-report/application-report"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                (user.user.utype == 1 &&
                  item.id == InterfaceReport &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1) ||
                (user.user.utype == 2 &&
                  item.id == InterfaceReport &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1)
            ) != -1
              ? ApplicationReportPage
              : ErrorPage7
          }
        />
        <ContentRoute
          path="/interface-report/stock-report"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                (user.user.utype == 1 &&
                  item.id == InterfaceReport &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1) ||
                (user.user.utype == 2 &&
                  item.id == InterfaceReport &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1)
            ) != -1
              ? StockReportPage
              : ErrorPage7
          }
        />
        <Route path="/introduction" component={IntroPage} />
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        {/* <ContentRoute path="/my-page" component={MyPage} /> */}
        {/* <Route path="/google-material" component={GoogleMaterialPage} /> */}
        {/* <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
        {/* <Route path="/e-commerce" component={ECommercePage} /> */}
        <Route path="/client-portal" component={ClientPortalPage} />
        <ContentRoute
          path="/client-management"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                item.id == ClientManagement &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
            ) != -1
              ? ClientManagementPage
              : ErrorPage7
          }
        />
        <ContentRoute
          path="/application-management"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                (user.user.utype == 1 &&
                  item.id == ApplicationManagementCAK &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1) ||
                (user.user.utype == 2 &&
                  item.id == ApplicationManagementClient &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1)
            ) != -1
              ? ApplicationManagementPage
              : ErrorPage7
          }
        />
        <ContentRoute
          path="/web-users"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                (user.user.utype == 1 &&
                  item.id == WebUsers &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1) ||
                (user.user.utype == 2 &&
                  item.id == WebUsers &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1)
            ) != -1
              ? WebUserPage
              : ErrorPage7
          }
        />
        <ContentRoute
          path="/web-roles"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                (user.user.utype == 1 &&
                  item.id == WebRole &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1) ||
                (user.user.utype == 2 &&
                  item.id == WebRole &&
                  item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                    -1)
            ) != -1
              ? WebRolePage
              : ErrorPage7
          }
        />
        <ContentRoute
          path="/resource-management"
          component={ResourceManagementPage}
        />
        <ContentRoute path="/configuration" component={ConfigurationPage} />
        <ContentRoute
          path="/company-profile"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                item.id == CompanyProfile &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
            ) != -1
              ? CompanyProfilePage
              : ErrorPage7
          }
        />
        <ContentRoute
          path="/settings"
          component={
            user.sections &&
            user.sections.findIndex(
              (item) =>
                item.id == Settings &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
            ) != -1
              ? SettingPage
              : ErrorPage7
          }
        />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
