import {createSlice} from "@reduxjs/toolkit";

const initialWebUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  webUserForEdit: undefined,
  loadData:undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const webUsersSlice = createSlice({
  name: "webUsers",
  initialState: initialWebUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    webUsersLoadedData: (state, action) => {
      state.error = null;
      state.loadData = action.payload.loadData;
      state.actionsLoading = false;
    },
    // getWebUserById
    webUserFetched: (state, action) => {
      state.actionsLoading = false;
      state.webUserForEdit = action.payload.webUserForEdit && action.payload.webUserForEdit.data;
      state.error = null;
    },
    resetedPasswordWebUser: (state, action) => {
      state.error = null;
      state.actionsLoading = false; 
     },
    // findWebUsers
    webUsersFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = list;
      state.totalCount = totalCount;
    },
    // createWebUser
    webUserCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.webUser);
    },
    // updateWebUser
    webUserUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.webUser.id) {
      //     return action.payload.webUser;
      //   }
      //   return entity;
      // });
    },
    // deleteWebUser
    webUserDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteWebUsers
    webUsersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // webUsersUpdateState
    webUsersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
