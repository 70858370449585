import {createSlice } from "@reduxjs/toolkit";

const initialApplicationManagementsState = {
  loadData:null,
  listLoading: false,
  actionsLoading: false,
  listLoadingNumbersList: false,
  totalCount: 0,
  listLoadingPending: false,
  totalCountPending: 0,
  listLoadingRejected: false,
  totalCountRejected: 0,
  listLoadingApproved: false,
  totalCountApproved: 0,
  listLoadingPaymentPending: false,
  totalCountPaymentPending: 0,
  listLoadingCancellationPending: false,
  totalCountCancellationPending: 0,
  listLoadingBundle: false,
  totalCountBundle: 0,
  entities: null,
  numbersList: null,
  totalCountNumbersList: 0,
  applicationManagementsPending:null,
  applicationManagementsRejected:null,
  applicationManagementsApproved:null,
  applicationManagementsPaymentPending:null,
  applicationManagementsCancellationPending:null,
  applicationManagementForEdit: undefined,
  applicationManagementData: undefined,
  applicationManagementBundle: [],
  fileName:{
        generalAttachment : "",
        authorizeAttachment : "",
        compAttachment : "",
        frameworkAttachment : "",
        rejectionAttachment : "",
        otherAttachment : ""},
  lastError: null,
  creationId:undefined,
  statusCode:null,
  isAllowed:true,
  isAllowedMsg:""
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const applicationManagementsSlice = createSlice({
  name: "applicationManagements",
  initialState: initialApplicationManagementsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        // state.listLoadingPending = false;
        // state.listLoadingRejected = false;
        // state.listLoadingApproved = false;
        // state.listLoadingPaymentPending = false;
        // state.listLoadingCancellationPending = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        // state.listLoadingPending = true;
        // state.listLoadingRejected = true;
        // state.listLoadingApproved = true;
        // state.listLoadingPaymentPending = true;
        // state.listLoadingCancellationPending = true;
      } else {
        state.actionsLoading = true;
      }
    },
    applicationManagementsLoadedData: (state, action) => {
      state.error = null;
      state.loadData = action.payload.loadData;
      state.actionsLoading = false;
    },
    checkedIfAllowedToAdd: (state, action) => {
      state.error = null;
      state.isAllowed = action.payload.isAllowed;
      state.isAllowedMsg = action.payload.isAllowedMsg;
      state.actionsLoading = false;
    },
    applicationCancellationManagementApproved: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    applicationCancellationManagementRejected: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // getApplicationManagementById
    applicationManagementFetched: (state, action) => {
      state.actionsLoading = false;
      state.applicationManagementForEdit = action.payload.applicationManagementForEdit.data;
      state.error = null;
    },
    applicationManagementEmptyFetched: (state, action) => {
      state.actionsLoading = false;
      state.applicationManagementData = action.payload.applicationManagementData;
      state.creationId = action.payload.creationId;
      state.fileName.generalAttachment = "";
      state.fileName.authorizeAttachment = "";
      state.fileName.compAttachment = "";
      state.fileName.frameworkAttachment = "";
      state.fileName.otherAttachment = "";
      state.applicationManagementForEdit = action.payload.applicationManagementForEdit && action.payload.applicationManagementForEdit.data;
      state.error = null;
    },
    // findApplicationManagements
    applicationManagementsPendingFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingPending = false;
      state.error = null;
      state.applicationManagementsPending = list;
      state.totalCountPending = totalCount;
    },
    // findApplicationManagements
    findApplicationManagementsBundleFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingBundle = false;
      state.error = null;
     state.applicationManagementBundle = list;
      state.totalCountBundle = totalCount;
      //console.log(totalCount);

      // state.applicationManagementBundle = [{
      //   id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //   range: "10000-10100"
      // },{
      //   id: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
      //   range: "20000-20100"
      // },{
      //   id: "3fa85f64-5717-4562-b3fc-2c963f66afa8",
      //   range: "30000-30100"
      // },{
      //   id: "3fa85f64-5717-4562-b3fc-2c963f66afa9",
      //   range: "40000-40100"
      // },{
      //   id: "3fa85f64-5717-4562-b3fc-2c963f66af10",
      //   range: "50000-50100"
      // }];
      // state.totalCountBundle = 1000;
    },
    // findApplicationManagements
    applicationManagementApprovedEmptyFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingBundle = false;
      state.error = null;
      state.applicationManagementBundle = list;
      state.totalCountBundle = totalCount;
      //console.log(totalCount);
    },
    applicationManagementRejectEmptyFetched: (state, action) => {
      state.fileName.rejectionAttachment = "";
      state.error = null;
    },
    // findApplicationManagements
    applicationManagementsPaymentPendingFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingPaymentPending = false;
      state.error = null;
      state.applicationManagementsPaymentPending = list;
      state.totalCountPaymentPending = totalCount;
    },
    // findApplicationManagements
    applicationManagementsCancellationPendingFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingCancellationPending = false;
      state.error = null;
      state.applicationManagementsCancellationPending = list;
      state.totalCountCancellationPending = totalCount;
    },
    // findApplicationManagements
    applicationManagementsRejectedFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingRejected = false;
      state.error = null;
      state.applicationManagementsRejected = list;
      state.totalCountRejected = totalCount;
    },
    attachmentUploaded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.fileName = action.payload.fileName
    },
    listDetailsFetched: (state, action) => {
      const { totalCount, data } = action.payload;
      state.listLoadingNumbersList = false;
      state.listLoading = false;
      state.error = null;
      state.numbersList = data;
      state.totalCountNumbersList = totalCount;
    },
    // findApplicationManagements
    applicationManagementsApprovedFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoadingApproved = false;
      state.error = null;
      state.applicationManagementsApproved = list;
      state.totalCountApproved = totalCount;
    },
    // findApplicationManagements
    notConfirmedNumbersFetched: (state, action) => {
      const { totalCount, data } = action.payload;
      state.listLoadingNotConfirmed = false;
      state.error = null;
      state.applicationManagementData = data.map(
        el => el.state = 0
      );
      state.applicationManagementData = data;
      state.totalCountNotConfirmed = totalCount;
      // console.log(state.applicationManagementData)
    },
    // createApplicationManagement
    applicationManagementCreated: (state, action) => {
      state.creationId = action.payload.id;
      state.statusCode = action.payload.statusCode;
      state.actionsLoading = false;
      state.error = null;
      // state.applicationManagementData = action.payload.applicationManagementData;

      // state.entities.push(action.payload.applicationManagement);
    },
    // updateApplicationManagement
    applicationManagementUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.applicationManagement.id) {
          return action.payload.applicationManagement;
        }
        return entity;
      });
    },
    // deleteApplicationManagement
    excludedNumbers: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // console.log(action.payload.excludeNumbersObj.numbers)
      // console.log(state.applicationManagementData)
      // // const new = state.applicationManagementData.map(el =>(
      // //   el.number
      // // ));
      // state.applicationManagementData = state.applicationManagementData.map(entity => {
      //   if (action.payload.excludeNumbersObj.numbers.findIndex(id => id == entity.number) > -1) {
      // console.log("inn")
      //     entity.state = 1;
      //   }
      //   return entity;
      // });
      // state.applicationManagementData = state.applicationManagementData.filter(
      //   el => !action.payload.excludeNumbersObj.includes(el.number)
      // );
    },
    // deleteApplicationManagement
    applicationManagementDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteApplicationManagement
    applicationManagementCanceled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // usageUploaded
    usageUploaded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // invoiceUploaded
    invoiceUploaded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteApplicationManagements
    applicationManagementsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    applicationManagementApproved: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    applicationManagementRejected: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    applicationPaymentManagementRejected: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    applicationPaymentManagementApproved: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    applicationManagementCancellationApproved: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    adminExported: (state, action) => {
    state.actionsLoading = false;
    state.downloadLink = action.payload.downloadLink;
    state.error = null;
  },
    // applicationManagementsUpdateState
    applicationManagementsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
