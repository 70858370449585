import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Input, InputNumber, Select } from "../../../../_metronic/_partials/controls";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../ClientPortal/_redux/companyProfile/companyProfilesActions";
import { download, toastMe,Encrypt } from "../../../../_components/Axios";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link, Switch, Redirect } from "react-router-dom";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

const companyProfile = {
};

var fileNew = [];
var legDocTypeEdit = [];
var idUploadedEdit = [];

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [sub, isSub] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  // Validation schema
  const CompanyProfileEditSchema = Yup.object().shape({
    name: Yup.string().trim()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Name is required"),
    address: Yup.string().trim().required("Address is required"),
    // idLicenseType: Yup.string()
    //   .required("License Type is required")
    //   .nullable(),
    // idClientType: Yup.string().required("Client Type is required"),
    licenseNumber: Yup.number("License Number must be a number").required("License Number is required"),
    contactPerson: Yup.string().trim().required("Contact Person is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    postalAddress: Yup.string().trim().required("Postal Address is required"),
    phoneNumber: Yup.string().trim()
    .max(15, "Maximum 15 digits").matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is required"),
    countryIso2: Yup.string().required("Country Name is required"),
    city: Yup.string().trim().required("City is required"),
    password: Yup.string().required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both passwords need to be the same"
        ),
      })
      .required("Password is required"),
  });

  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(actions.companyProfilesLoadData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, loadData } = useSelector(
    (state) => ({
      loadData: state.companyProfiles.loadData,
      actionsLoading: state.companyProfiles.actionsLoading,
    }),
    shallowEqual
  );

  const saveCompanyProfile = (values) => {
    // console.log(values)
    // if (!id) {
    //   dispatch(actions.createCompanyProfile(values)).then(() => backToCompanyProfilesList());
    // } else {
      isSub(true)
      values.password = Encrypt(values.password)
      values.confirmPassword = Encrypt(values.confirmPassword)
    dispatch(actions.addCompanyProfile(values))
      .then((response)=>{
      values.password = ""
      values.confirmPassword = ""
      isSub(false)
        // console.log(response)
        if(response.statusCode.code==0) setIsRequested(true)
      }
      
      // dispatch(actions.fetchCompanyProfile())
      ).catch(() => {
      values.password = ""
      values.confirmPassword = ""
      isSub(false)
        setIsRequested(false);
      });
    // }
  };
  const [showHidePassword1, changeShowHidePassword1] = useState(false);
  const [showHidePassword2, changeShowHidePassword2] = useState(false);
  const [value, setValue] = useState();

  var attach = [];
  const [ClientId, SetClientId] = useState("");
  const [LegalDocumentTypeId, SetLegalDocumentTypeId] = useState("");
  const [idUploaded, setIdUploaded] = useState([]);
  // console.log(ClientId);

  const [inf, setIn] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  const [file, setFile] = useState([]);
  function handleChange(event) {
    let obj = {
      // id: event.target.id.split("upload-")[1],
      idLegalDocumentType: event.target.id.split("upload-")[1],
      fileObj: event.target.files[0],
    };
    fileNew.find((x) => x.idLegalDocumentType == event.target.id)
      ? (fileNew.find((x) => x.idLegalDocumentType == event.target.id).fileObj =
          event.target.files[0])
      : fileNew.push(obj);
    setFile([...fileNew]);
  }

  const { fileName } = useSelector(
    (state) => ({
      fileName: state.companyProfiles.fileName,
    }),
    shallowEqual
  );

  function handleSubmitUpload(id) {
    setUploaded(true)
    const formData = new FormData();
    formData.append(
      "file",
      fileNew.filter((x) => x.idLegalDocumentType == id)[0].fileObj
    );
    formData.append(
      "fileName",
      fileNew.filter((x) => x.idLegalDocumentType == id)[0].fileObj.name
    );

    dispatch(
      actions.uploadAttachment({
        data: formData,
        idLegalDocumentType: id,
      })
    ).then(() => {
      setUploaded(false)
      setIdUploaded([...idUploaded, id]);
    });
  }
  function handleDeleteUpload(idDel) {
    dispatch(actions.deleteAttachment({ idLegalDocumentType: idDel }));
    fileNew = fileNew.filter((x) => x.idLegalDocumentType != idDel);
    setFile([...fileNew]);
    setIdUploaded(idUploaded.filter((x) => x != idDel));
  }

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: RegistrationSchema,
  //   onSubmit: (values, { setStatus, setSubmitting }) => {
  //     setSubmitting(true);
  //     enableLoading();
  //     register(values.email, values.fullname, values.username, values.password)
  //       .then(({ data: { authToken } }) => {
  //         props.register(authToken);
  //         disableLoading();
  //         setSubmitting(false);
  //       })
  //       .catch(() => {
  //         setSubmitting(false);
  //         setStatus(
  //           intl.formatMessage({
  //             id: "AUTH.VALIDATION.INVALID_LOGIN",
  //           })
  //         );
  //         disableLoading();
  //       });
  //   },
  // });

  return (
    <>
    {isRequested && <Redirect to="/auth/login" />}
      {!isRequested && (
        <>
    <div className="text-center mb-10 mb-lg-20" style={{marginTop: "-1030px"}}>
      
    <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
              <img
                alt="Logo"
                className="max-h-70px p-3"
                src={toAbsoluteUrl("/media/logos/logo-dark.png")}
              />
            </Link>
    </div>
    <Card style={{width:"70%"}}>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title="Registration"></CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            enableReinitialize={true}
            initialValues={companyProfile}
            validationSchema={CompanyProfileEditSchema}
            validate={(values) => {
              const errors = {};
              if (!/^\d+$/.test(values.licenseNumber)) {
                errors.licenseNumber = 'Only numbers are allowed for the license number';
              }
              return errors;
            }}
            onSubmit={(values) => {
              values.attachments = fileName;
              values.idClientType = ClientId;
              values.licenseNumber = values.licenseNumber.toString();
              if (idUploaded.length != LegalDocumentTypeId.length) {
                toastMe(true, "Please Upload Required Documents", "warning");
              } else if (values.idClientType == "") {
                toastMe(true, "Please Select Company Type", "warning");
              } else {
                // console.log(values)
                saveCompanyProfile(values);
              }
            }}
          >
            {({ handleSubmit,  errors,touched,isSubmitting }) => (
              <>
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  <h5>Company Details</h5>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Client Name <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Client Name"
                        label="Client Name"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Postal Address <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Field
                        name="postalAddress"
                        component={Input}
                        placeholder="Postal Address"
                        label="Postal Address"
                        autoComplete="postalAddress"
                      />
                    </div>
                  </div>
                  {/* <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      License Type <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Select name="idLicenseType" label="License Type *">
                        <option value="">Select License Type</option>
                        {loadData &&
                          loadData.licenseTypes.map((x) => (
                            <option value={x.value}>{x.name}</option>
                          ))}
                      </Select>
                      <ErrorMessage
                        name="idLicenseType"
                        component="div"
                        className="field-error"
                      />
                    </div>
                  </div> */}
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      License Number <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Field
                        name="licenseNumber"
                        component={Input}
                        placeholder="License Number"
                        label="License Number"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Company Type <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Select
                        name="idClientType"
                        label="Company Type"
                        value={ClientId}
                        onChange={(e) => {
                          dispatch(actions.deleteAllAttachment());
                          SetClientId(e.target.value);
                          SetLegalDocumentTypeId(
                            loadData.clientTypesLegalDocumentTypes.filter(
                              (x) => x.idClientType == e.target.value
                            )
                          );
                        setFile([])
                        setUploaded(false)
                        setIdUploaded([])
                        fileNew=[]
                        }}
                      >
                        <option value="">Select Company Type</option>
                        {loadData &&
                          loadData.clientTypes.map((x) => (
                            <option value={x.value}>{x.name}</option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Attachments <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      {LegalDocumentTypeId &&
                        LegalDocumentTypeId.map((val) => (
                          <div className="row pr-3 pl-4">
                            {/* <span>{val.idLegalDocumentType}</span> */}
                            <div className="col-12 mx-0 px-0">
                              <h6>
                                {loadData &&
                                  loadData.legalDocumentTypes.filter(
                                    (x) => x.value == val.idLegalDocumentType
                                  )[0].name}
                              </h6>
                            </div>
                            {file.find(
                              (x) =>
                                x.idLegalDocumentType == val.idLegalDocumentType
                            ) ? (
                              <></>
                            ) : (
                              <div>
                                <label
                                  htmlFor={"upload-" + val.idLegalDocumentType}
                                  style={{
                                    background: "#3386C8",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    color: "#fff",
                                  }}
                                >
                                  select File
                                </label>
                                <input
                                  type="file"
                                  name={"upload-" + val.idLegalDocumentType}
                                  style={{ visibility: "hidden", width: "2px" }}
                                  id={"upload-" + val.idLegalDocumentType}
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                            <label
                              htmlFor={"upload-" + val.idLegalDocumentType}
                              style={{ padding: "2px 2px" }}
                            >
                              {file.find(
                                (x) =>
                                  x.idLegalDocumentType ==
                                  val.idLegalDocumentType
                              )
                                ? ""
                                : // file.find(x=>x.id==val.idLegalDocumentType).fileObj.name
                                  "No File Selected"}
                            </label>
                            {file.findIndex(
                              (x) =>
                                x.idLegalDocumentType == val.idLegalDocumentType
                            ) != -1 &&
                              file[
                                file.findIndex(
                                  (x) =>
                                    x.idLegalDocumentType ==
                                    val.idLegalDocumentType
                                )
                              ] &&
                              file[
                                file.findIndex(
                                  (x) =>
                                    x.idLegalDocumentType ==
                                    val.idLegalDocumentType
                                )
                              ].fileObj &&
                              file[
                                file.findIndex(
                                  (x) =>
                                    x.idLegalDocumentType ==
                                    val.idLegalDocumentType
                                )
                              ].fileObj.name}
                            {file.find(
                              (x) =>
                                x.idLegalDocumentType == val.idLegalDocumentType
                            ) ? (
                              <div className="ml-auto">
                                {idUploaded.findIndex(
                                  (x) => x == val.idLegalDocumentType
                                ) == -1 && (
                                  <button
                                    type="button"
                                    disabled={uploaded}
                                    className="btn-light btn-sm mx-1"
                                    onClick={() =>
                                      handleSubmitUpload(
                                        val.idLegalDocumentType
                                      )
                                    }
                                  >
                                    {
                                  uploaded?  <><span class="spinner-border spinner-border-sm pr-1" role="status" aria-hidden="true"></span>Uploading...</>
                                  :"Upload"
                                }
                                  </button>
                                )}
                                <button
                                  type="button"
                                  disabled={uploaded}
                                  className="btn-danger btn-sm mx-1"
                                  onClick={() =>
                                    handleDeleteUpload(val.idLegalDocumentType)
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <h5>Contact Details</h5>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Contact Person <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Field
                        name="contactPerson"
                        component={Input}
                        placeholder="Contact Person"
                        label="Contact Person"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Email <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Field
                        name="email"
                        type="email"
                        component={Input}
                        placeholder="Email"
                        label="Email"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Phone Number <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Field
                        name="phoneNumber"
                        component={Input}
                        placeholder="Phone Number"
                        label="Phone Number"
                      />
                    </div>
                    {/* <PhoneInput
  placeholder="Enter phone number"
  value={value}
  onChange={setValue}
  error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}/> */}
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Country <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Select name="countryIso2" label="Country">
                        <option value="">Select Country</option>
                        {loadData &&
                          loadData.countries.map((x) => (
                            <option value={x.iso2}>{x.name}</option>
                          ))}
                      </Select>
                      
                  {errors.countryIso2 && touched.countryIso2 ? (
                    <span className="field-error">{errors.countryIso2}</span>
                  ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Address <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Field
                        name="address"
                        component={Input}
                        placeholder="Address"
                        label="Address"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      City <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <Field
                        name="city"
                        component={Input}
                        placeholder="City"
                        label="City"
                      />
                    </div>
                  </div>
                  {!sub &&
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Password
                      <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <i
                        className={
                          showHidePassword1
                            ? "passIcon1 fa fa-eye-slash icon"
                            : "passIcon1 fa fa-eye icon"
                        }
                        onClick={() =>
                          changeShowHidePassword1(!showHidePassword1)
                        }
                      ></i>
                      <Field
                        name="password"
                        component={Input}
                        label="Password"
                        placeholder="Password"
                        autocomplete="new-password"
                        type={showHidePassword1 ? "text" : "password"}
                      />
                    </div>
                  </div>
}
                  {/* Confirm Password*/}
                  {!sub &&
                  <div className="form-group row">
                    <label className="col-lg-3 text-bold col-form-label text-lg-left">
                      Confirm Password
                      <span className="ast">*</span>
                    </label>
                    <div className="col-lg-9 col-xl-9">
                      <i
                        className={
                          showHidePassword2
                            ? "passIcon1 fa fa-eye-slash icon"
                            : "passIcon1 fa fa-eye icon"
                        }
                        onClick={() =>
                          changeShowHidePassword2(!showHidePassword2)
                        }
                      ></i>
                      <Field
                        name="confirmPassword"
                        component={Input}
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        type={showHidePassword2 ? "text" : "password"}
                      />
                    </div>
                  </div>
}
                  {/* <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                  </button> */}
                </Form>
      <CardFooter>
                <CardHeaderToolbar className="text-center">
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-white font-weight-bold px-9 py-4 my-3 "
                >
                🡐  Back
                </button>
              </Link>
                  <button
                    type="submit"
                    disabled={sub}
                    className="btn btn-primary ml-2 text-center"
                    // onClick={saveCompanyProfileClick}
                    onClick={() => handleSubmit()}
                  >
                    
                   {sub?<><span class="spinner-border spinner-border-sm pr-1" role="status" aria-hidden="true"></span>Register...</>:"Register"}
                  </button>
                  {/* <button
                    type="submit"
                    className="btn btn-primary ml-2 text-center"
                    // onClick={saveCompanyProfileClick}
                    onClick={() => handleSubmit()}
                  >
                    Register
                  </button> */}
                </CardHeaderToolbar></CardFooter>
              </>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
    </>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
