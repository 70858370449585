import {createSlice} from "@reduxjs/toolkit";

const initialStockingResourcesState = {
  listLoading: false,
  listLoadingNumbersList: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  numbersList: null,
  totalCountNumbersList: 0,
  loadData: null,
  stockingResourceForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const stockingResourcesSlice = createSlice({
  name: "stockingResources",
  initialState: initialStockingResourcesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    stockingResourcesLoadedData: (state, action) => {
      state.error = null;
      state.loadData = action.payload.loadData;
      state.actionsLoading = false;
    },
    // getStockingResourceById
    stockingResourceFetched: (state, action) => {
      state.actionsLoading = false;
      state.stockingResourceForEdit = action.payload.stockingResourceForEdit;
      state.error = null;
    },
    // findStockingResources
    stockingResourcesFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = list;
      state.totalCount = totalCount;
    },
    // findStockingResources
    listDetailsFetched: (state, action) => {
      const { totalCount, data } = action.payload;
      state.listLoadingNumbersList = false;
      state.listLoading = false;
      state.error = null;
      state.numbersList = data;
      state.totalCountNumbersList = totalCount;
    },
    // createStockingResource
    stockingResourceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.stockingResource);
    },
    // updateStockingResource
    stockingResourceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.stockingResource.id) {
          return action.payload.stockingResource;
        }
        return entity;
      });
    },
    // deleteStockingResource
    stockingResourceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteStockingResources
    stockingResourcesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // stockingResourcesUpdateState
    stockingResourcesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
