export default [
  {
    id: "eac021fe-5277-29a3-c9a1-59c1c4c852cd",
    idClient: "e5c69f3b-b709-691c-1ddd-dfa321d0fa58",
    clientName: "Safaricom",
    resourceType: "fxnfyddixw",
    count: 30,
    reserved: 135,
    quarantine: 227,
    assigned: 230,
    availableDate: "5/5/2018 12:37:38 AM",
    totalAmount: 544
  },
  {
    id: "46448b32-5381-64d5-8882-eb1410cdc967",
    idClient: "94bed935-b0a8-691e-f403-3fe3dc8f95a8",
    clientName: "Telkom Kenya",
    resourceType: "mtngclbtgp",
    count: 30,
    reserved: 239,
    quarantine: 46,
    assigned: 168,
    availableDate: "8/13/2006 4:20:03 PM",
    totalAmount: 124
  },
  {
    id: "697f1ab0-3380-29ac-378e-62d2fdfd9af9",
    idClient: "4dee1e29-219a-3fcf-cfca-729809959bb0",
    clientName: "Safaricom",
    resourceType: "octwldclkn",
    count: 14,
    reserved: 224,
    quarantine: 237,
    assigned: 62,
    availableDate: "1/11/2007 11:48:40 AM",
    totalAmount: 10
  }
];
