/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { getPages, getPagesCount } from "../../../_helpers";

export function PaginationRangeLinks({ paginationProps }) {
  // console.log(paginationProps)
  const { totalSize, sizePerPage, page, paginationSize } = paginationProps;
  const pagesCount = getPagesCount(totalSize, sizePerPage);
  const pages = getPages(page, pagesCount, paginationSize);
  const handleFirstPage = ({ onPageChange }) => {
    onPageChange(0);
  };

  const handlePrevPage = ({ page, onPageChange }) => {
    onPageChange(page - 1);
  };

  const handleNextPage = ({ page, onPageChange }) => {
    if (page + 1 < pagesCount) {
      onPageChange(page + 1);
    }
  };

  const handleLastPage = ({ onPageChange }) => {
    onPageChange(pagesCount - 1);
  };

  const handleSelectedPage = ({ onPageChange }, pageNum) => {
    onPageChange(pageNum - 1);
  };
  const disabledClass = pagesCount > 1 ? "" : "disabled";
  // const disabledClasss = page > 0 ? "" : "disabled";
  return (
    <>
      <div className={`d-flex flex-wrap py-2 mr-3 ${disabledClass}`}>
        {pagesCount < 2 && <></>}
        {page > 0 && (
          <>
            {/* <a
              onClick={() => handleFirstPage(paginationProps)}
              className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
            >
              <i className="ki ki-bold-double-arrow-back icon-xs" />
            </a> */}

            {/* {pagesCount == 0 && ( */}
            <a
              onClick={() => handlePrevPage(paginationProps)}
              className={`btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1`}
            >
              <i className="ki ki-bold-arrow-back icon-xs" />
            </a>
          </>
        )}
        {/* )} */}
        {pagesCount > 1 && (
          <>
            {/* {page > 2 &&  (
              <a className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
                ...
              </a>
            )} */}
            {/* {pages.map((p) => (
              <a
                key={p}
                onClick={() => handleSelectedPage(paginationProps, p)}
                className={`btn btn-icon btn-sm border-0 btn-light ${
                  page + 1 === p ? " btn-hover-primary active" : ""
                } mr-2 my-1`}
              >
                {p}
              </a>
            ))} */}
              <a
                // key={p}
                // onClick={() => handleSelectedPage(paginationProps, p)}
                className={`btn btn-icon btn-sm border-0 btn-light btn-hover-primary active mr-2 my-1`}
              >
                {page+1}
              </a>
            {/* {page < pagesCount - 4 && (
              <a className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
                ...
              </a>
            )} */}
            {page + 1 != pagesCount && (
              <>
            <a
              onClick={() => handleNextPage(paginationProps)}
              className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
            >
              <i className="ki ki-bold-arrow-next icon-xs"></i>
            </a>
            {/* <a
              onClick={() => handleLastPage(paginationProps)}
              className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
            >
              <i className="ki ki-bold-double-arrow-next icon-xs"></i>
            </a> */}
            </>
            )}
          </>
        )}
      </div>
    </>
  );
}
