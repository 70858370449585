import axiosdotnet from "./axiosdotnet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KTCookie } from "../_metronic/_assets/js/components/cookie";
import { Router } from "react-router";
import { actionTypes } from "../app/modules/Auth/_redux/authRedux";
// import { refreshToken,logout } from "../app/modules/Auth/_redux/authCrud";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { sha256 } from "js-sha256"

//const { i18n,t } = useTranslation();


export const sendRequestDotNet = async (
  link,
  data = {},
  headers = {},
  csv = false,
  method = "POST"
) => {
  let contentType = "application/json";
  if (data instanceof FormData) contentType = "multipart/form-data";

  headers = {
    ...headers,
    "content-type": contentType,
    //'IdLanguage':(i18n.language === 'en')?1:(i18n.language === 'ar'?2:3)
    // IdLanguage: 1,
  };

  // let uId=getCookie("user_id");
  // let uAu = getCookie("user_auth");
  // if(uAu!=undefined)
  // {
  // var ss = KTCookie.getCookie("jwt");

  // console.log(JSON.parse(localStorage.getItem('persist:v726-demo1-auth')));
  // console.log(link);
  var ss = localStorage.getItem('ab');
  if (ss == undefined) {
    ss = "";
  }
  headers = {
    ...headers,
    post: {
      //Token:uId.replace(/\s/g, '').replace('+', ''),
      Authorization: "Bearer " + KTCookie.getCookie("tt"),
      IdLanguage: KTCookie.getCookie("language") ? KTCookie.getCookie("language") : 1,
      IdChannel: 5,
      UType: (link.toLowerCase() == "companyprofile/add") ? 2 : (KTCookie.getCookie("uu") ? KTCookie.getCookie("uu") : 1),
      Token: KTCookie.getCookie("cc") ? KTCookie.getCookie("cc") : ""
      // Authorization: "Bearer "
    },
  };

  if ((link.toLowerCase() === "applicationmanagement/getbundlesforassignment") && (KTCookie.getCookie("uu") === "2")) {
    return;
  }

  //     //headers.post['Token'] =
  // }
  let axiosInst = null;
  if (method === "POST")
    axiosInst = axiosdotnet.post(link, data, { headers: headers });
  else axiosInst = axiosdotnet.get(link, { headers: headers });

  var is401 = false;
  var canRT = false;
  var inst = await axiosInst
    .then((response) => {
      let MsgResponse = "Something went wrong! Please try again";
      if (typeof response !== "undefined" && csv) {
        var header = response.headers;
        var fileName = header["content-disposition"].split("''")[1];
        // console.log(header)
        // //header.split("=")[1];
        // console.log(header["content-disposition"].split("''")[1])
        // for (var i = 0; i < header.length; i++) {

        // console.log(header[i])
        // 	if (header[i].key == "content-disposition") {
        // 		fileName = header[i].value[1].split("=")[1];
        // 		break;
        // 	}
        // }
        //fileName = fileName+".csv"
        var blob = new Blob([response.data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        return {
          error: false,
          data: response.data,
        };
      }
      if (typeof response.data !== "undefined") {
        //  console.log(response.data)
        if (typeof response.data.statusCode !== "undefined") {
          if (typeof response.data.statusCode.message !== "undefined") {
            MsgResponse = response.data.statusCode.message;
          }
          if (
            typeof response.data.statusCode.code !== "undefined" &&
            (response.data.statusCode.code === 0 ||
              response.data.statusCode.code === 104 ||
              response.data.statusCode.code === 108 ||
              response.data.statusCode.code === 113 ||
              response.data.statusCode.code === 170 ||
              response.data.statusCode.code === 175 ||
              response.data.statusCode.code === 176 ||
              response.data.statusCode.code === 177 ||
              response.data.statusCode.code === 178 ||
              response.data.statusCode.code === 184 ||
              response.data.statusCode.code === 186 ||
              response.data.statusCode.code === 202 ||
              response.data.statusCode.code === 173 ||
              // response.data.statusCode.code === 101 ||
              response.data.statusCode.code === 159)
          ) {
            return {
              error: false,
              data: response.data,
            };
          }
          else
            if (response.data.statusCode.code === 11) {
              axiosdotnet.post("User/Logout", {}, { headers: headers })
                .then((response) => {
                  window.location.replace("/logout")
                }

                )
            }
            else
              if (response.data.statusCode.code === 17 || response.data.statusCode.code === 16) {
                // toastMe(true, MsgResponse);
                // window.location.replace("/error/error-unauthorized")
                is401 = true;
                if (canRT) {
                  KTCookie.setCookie("AD", true)
                  axiosdotnet.post("User/Logout", {}, { headers: headers })
                    .then((response) => {
                      window.location.replace("/logout")
                    }

                    )
                }
              }
        }

        //// special if no statusCode ////
        if (typeof response.data.code !== "undefined") {
          if (typeof response.data.message !== "undefined") {
            MsgResponse = response.data.message;
          }

          if (
            typeof response.data.code !== "undefined" &&
            response.data.code === 0
          ) {
            return {
              error: false,
              data: response.data,
            };
          }
          else
            if (response.data.code === 17) {
              toastMe(true, MsgResponse);
              // window.location.replace("/error/error-unauthorized")
              KTCookie.setCookie("AD", true)
              axiosdotnet.post("User/Logout", {}, { headers: headers })
                .then((response) => {
                  window.location.replace("/logout")
                }

                )
            }
            else if (response.data.statusCode.code == 1) {
              toastMe(true, response.data.statusCode.message);
            }
        }
        //// special if no statusCode ////
      }
      if (response.code == 1) {
        toastMe(true, response.message);
      }
      if (response.data.statusCode.code == 1) {
        toastMe(true, response.data.statusCode.message);
      }
      // 
      // toastMe(true, MsgResponse,"warning");
      return {
        error: true,
        message: MsgResponse,
      };
    })
    .catch((error) => {

      //console.log(error);
      let MsgResponse = "Something went wrong! Please try again";
      if (error.response.status === 401) {
        is401 = true;
        //console.log(link,is401);
      }
      else {
        toastMe(true, MsgResponse);

      return {
        error: true,
        message: MsgResponse,
        data: data,
      };
    }
    });

  //return inst;
  //console.log(is401);

  if (!is401) {
    return inst;
  } else if (!canRT) {
    await axiosdotnet.post("User/RefreshToken", {}, { headers: headers })
      // await refreshToken()
      .then((response) => {
        // console.log("RefreshToken::",response);
        if (response.data.statusCode.code === 0 || response.data.statusCode.code === 113) {
          canRT = true;
          // console.log(response);
          // let currentPath = window.location.pathname;
          // useHistory().push(currentPath);
          // auth.actions.refreshToken(
          //   response.data.user.token,
          //   response.data.roles,
          //   response.data.user,
          //   response.data.seasons
          // );
          KTCookie.setCookie("tt", response.data.user.token);
          return {
            error: false,
            data: response.data,
          };
          // actionTypes.RefreshToken(response.data)
          // setCookie("user_auth", response.data.profile.jwtToken);
          // console.log(response.data.user.token)
          // KTCookie.setCookie("jwt",response.data.user.token)
          // jwtToken=response.data.profile.jwtToken;
          // window.location.reload();
          // Router.replace(window.location.href);
        } else {
          // window.location.replace("/logout")
          // useHistory().push("/logout");
          // console.log(response);
          // redirect(context, "/login");
        }
      })
      .catch((error) => {
        let MsgResponse = "Something went wrong! Please try again";
        // console.log(error);

        //  window.location.replace("/logout")
        return {
          error: true,
          message: MsgResponse,
          data: data,
        };
        // useHistory().push("/logout");
        // redirect(context, "/login");
      });

    let contentType = "application/json";
    if (data instanceof FormData) contentType = "multipart/form-data";

    //////

    headers = {
      ...headers,
      Authorization: "Bearer " + KTCookie.getCookie("tt"),
      IdLanguage: KTCookie.getCookie("language") ? KTCookie.getCookie("language") : 1,
      IdChannel: 5,
      UType: KTCookie.getCookie("uu") ? KTCookie.getCookie("uu") : 1,
      Token: KTCookie.getCookie("cc") ? KTCookie.getCookie("cc") : ""
    };

    // let uAu = getCookie("user_auth");
    // if(uAu!=undefined)
    // {
    // headers = {
    // ...headers,
    // post:{
    //Token:uId.replace(/\s/g, '').replace('+', ''),
    // Authorization: "Bearer " + getCookie("user_auth")
    // }
    // }
    //headers.post['Token'] =
    // }
    let axiosInst = null;
    if (method === "POST")
      axiosInst = axiosdotnet.post(link, data, { headers: headers });
    else axiosInst = axiosdotnet.get(link, { headers: headers });

    return axiosInst
      .then((response) => {
        let MsgResponse = "Something went wrong! Please try again";
        if (typeof response.data !== "undefined") {
          if (typeof response.data.statusCode !== "undefined") {
            if (typeof response.data.statusCode.message !== "undefined") {
              MsgResponse = response.data.statusCode.message;
            }
            if (
              typeof response.data.statusCode.code !== "undefined" &&
              response.data.statusCode.code === 0
            ) {
              return {
                error: false,
                data: response.data,
              };
            }
          }

          //// special if no statusCode ////
          if (typeof response.data !== "undefined") {
            if (typeof response.data.statusCode !== "undefined") {
              // console.log(response.data)
              if (typeof response.data.statusCode.message !== "undefined") {
                MsgResponse = response.data.statusCode.message;
              }
              if (
                typeof response.data.statusCode.code !== "undefined" &&
                (response.data.statusCode.code === 0 ||
                  response.data.statusCode.code === 113 ||
                  response.data.statusCode.code === 104 ||
                  response.data.statusCode.code === 170 ||
                  response.data.statusCode.code === 175 ||
                  response.data.statusCode.code === 176 ||
                  response.data.statusCode.code === 177 ||
                  response.data.statusCode.code === 178 ||
                  response.data.statusCode.code === 180 ||
                  response.data.statusCode.code === 173 ||
                  // response.data.statusCode.code === 101 ||
                  response.data.statusCode.code === 159)
              ) {
                return {
                  error: false,
                  data: response.data,
                };
              }
              else
                if (response.data.statusCode.code === 11) {
                  // axiosdotnet.post("User/Logout", {}, { headers: headers })
                  //  .then((response) => {
                  //       window.location.replace("/logout")
                  //   }

                  //  )
                }
                else
                  if (response.data.statusCode.code === 17) {
                    toastMe(true, MsgResponse);
                    // window.location.replace("/error/error-unauthorized")
                    KTCookie.setCookie("AD", true)
                    window.location.replace("/logout")

                    // axiosdotnet.post("User/Logout", {}, { headers: headers })
                    //  .then((response) => {
                    //       window.location.replace("/logout")
                    //   }

                    //  )
                  }
            }

            //// special if no statusCode ////
            if (typeof response.data.code !== "undefined") {
              if (typeof response.data.message !== "undefined") {
                MsgResponse = response.data.message;
              }

              if (
                typeof response.data.code !== "undefined" &&
                response.data.code === 0
              ) {
                return {
                  error: false,
                  data: response.data,
                };
              }
            }
            //// special if no statusCode ////
          }

          //// special if no statusCode ////
        }

        // toastMe(true, MsgResponse,"warning");
        return {
          error: true,
          message: MsgResponse,
        };
      })
      .catch((error) => {
        //console.log(error);
        let MsgResponse = "Something went wrong! Please try again";
        if (error.response.status === 401) {
          // console.log(error);
          //  window.location.replace("/logout")
          // redirect(context, "/login");
        }
        else toastMe(true, MsgResponse);
        return {
          error: true,
          message: MsgResponse,
          data: data,
        };
      });
  }
};
export function toastMe(show = true, message = "", type = "", position = "") {
  if (!show) {
    toast.dismiss();
  } else {
    let p = toast.POSITION.TOP_CENTER;
    switch (position) {
      case "bl":
        p = toast.POSITION.BOTTOM_LEFT;
        break;
      case "tr":
        p = toast.POSITION.TOP_RIGHT;
        break;
      case "br":
        p = toast.POSITION.BOTTOM_RIGHT;
        break;
      case "tc":
        p = toast.POSITION.TOP_CENTER;
        break;
      case "tl":
        p = toast.POSITION.TOP_LEFT;
        break;
    }
    let tp = toast.TYPE.DEFAULT;
    switch (type) {
      case "dark":
        tp = toast.TYPE.DARK;
        break;
      case "error":
        tp = toast.TYPE.ERROR;
        break;
      case "info":
        tp = toast.TYPE.INFO;
        break;
      case "success":
        tp = toast.TYPE.SUCCESS;
        break;
      case "warning":
        tp = toast.TYPE.WARNING;
        break;
    }

    const options = {
      // onOpen: props => console.log(props.foo),
      // onClose: props => console.log(props.foo),
      // autoClose: 6000,
      // closeButton: FontAwesomeCloseButton,
      type: tp,
      position: p,
      // hideProgressBar: false,
      // pauseOnHover: true,
      // transition: MyCustomTransition,
      // progress: 0.2
    };
    toast(message, options);
  }
}

export const sendRequestImg = async (
  link,
  data = {},
  headers = {},
  method = "POST"
) => {
  let contentType = "application/json";
  if (data instanceof FormData) contentType = "multipart/form-data";

  headers = {
    ...headers,
    "content-type": contentType,
    IdLanguage: KTCookie.getCookie("language") ? KTCookie.getCookie("language") : 1,
  };
  let axiosInst = null;
  if (method === "POST")
    axiosInst = axiosdotnet.post(link, data, { headers: headers });
  else axiosInst = axiosdotnet.get(link, { headers: headers });

  var inst = await axiosInst
    .then((response) => {
      let MsgResponse = "Something went wrong! Please try again";
      if (typeof response.status !== "undefined") {
        if (response.status === 0 || response.status === 200) {
          return {
            error: false,
            data: response.data,
          };
        }
      }

      return {
        error: true,
        message: MsgResponse,
      };
    })
    .catch((error) => {
      return {
        error: true,
        message: "msg",
        data: data,
      };
    });

  return inst;
};

export const download = (e, name) => {
  // console.log(e);
  // console.log(e);
  let href = e.target ? e.target.href : e;
  toastMe(true, "Loading...", "warning");
  window.open(href, '_blank').focus()
  fetch(href, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      toastMe(false, "Loading...", "warning");
      if (response.headers.get('Content-Type').split("/")[1] == "msword") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download doc", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".doc"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get('Content-Type').split("/")[1] == "png") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get('Content-Type').split("/")[1] == "pdf") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get('Content-Type').split("/")[1] == "jpg") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".JPG"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get('Content-Type').split("/")[1] == "jpeg") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".jpeg"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get('Content-Type').split("/")[1] == "csv") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }

      if (response.headers.get('Content-Type').split("/")[1] == "vnd.ms-excel") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }

      //   console.log(response)
      // console.log(response.headers.get('Content-Type').split("/")[1])
      // response.arrayBuffer().then(function(buffer) {
      //   const url = window.URL.createObjectURL(new Blob([buffer]));
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.setAttribute("download", GUID()); //or any other extension
      //   document.body.appendChild(link);
      //   link.click();
      // });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const downloadJpg = (e, name) => {
  // console.log(e.target.href);
  fetch(e, {
    method: "GET",
    // mode: 'no-cors',
    headers: {
      //   'Access-Control-Allow-Origin':"*",
      //  "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
      //  'Content-Type': 'application/json',
    }
  })
    .then((response) => {
      //   console.log(response)
      if (response.status == 200) {
        toastMe(true, "Success", "success");
      } else if (response.status == 204) {
        toastMe(true, response.statusText, "warning");
      } else toastMe(true, "Cannot Download Image", "error");
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", GUID() + ".jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      toastMe(true, "Cannot Download Image", "error");
      console.log(err);
    });
};
export const downloadPdf = (e, name) => {
  // console.log(e.target.href);
  fetch(e, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      //   console.log(response)
      if (response.status == 200) {
        toastMe(true, "Success", "success");
      } else if (response.status == 204) {
        toastMe(true, response.statusText, "warning");
      } else toastMe(true, "Cannot Download PDF", "error");
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", GUID() + ".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      toastMe(true, "Cannot Download PDF", "error");
      console.log(err);
    });
};

export const nullChecker = (cell) => (!cell ? "N/A" : cell);
var nf = new Intl.NumberFormat();
//   nf.format(number);
export const parseNumber = (cellContent) =>
  !cellContent ? 0 : nf.format(parseInt(cellContent));


export const Encrypt = (msg) => {
  return sha256(msg)
}

export const GUID = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export const ConvertArrayToCommaSeperated = (array) => {
  return array.join(", ");
}

// function consecutiveTenths(str, consecutive, iterations){
//   var curr;
//       var prev;
//       var curr_tenth = 0;
//       var prev_tenth = 0;
//       var count = 0;
//       var count_tenth = 0;
//       var count_digit;
//       var prev_digit;
//       var curr_digit;

//   for(var i = 0; i < str.length; ++i) {
//       curr = parseInt(str.split('')[i]);
//       if(isNumeric(curr)) {
//           ++count;
//           if(count === iterations){
//               curr_digit = (prev * 10) + curr;
//               alert(count_digit + " " + curr_digit + " " + prev_tenth);
//               if(count_digit === 0){
//                   ++count_digit;
//               }
//               else if(curr_tenth === (prev_tenth + 1)){
//                   ++count_digit;
//                   if(count_digit === consecutive){
//                       return true;
//                   }
//               }
//               prev_digit = curr_digit;
//               count = 0;
//           }
//           else {
//               prev = curr;
//           }
//       }
//       else {
//           count = 0;
//       }
//   }
// }
export function ConsecutiveDigits(str, consecutive) {
  var curr,
    prev,
    count = 0;
  for (var i = 0; i < str.length; ++i) {
    curr = parseInt(str.split('')[i]);
    if (isNumeric(curr)) {
      if (count === 0) {
        ++count;
      }
      else if ((prev + 1 === curr || (/^(.)\1+$/.test(str.substring(1))) || (/^(.)\1+$/.test(str.slice(0, -1))))) {
        ++count;
        if (count === consecutive) {
          return true;
        }
      }
      prev = curr;
    }
    else return true
  }
  return false;
}


function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}


export const formatUTC = (dateInt, addOffset = false) => {
  let date = (!dateInt || dateInt.length < 1) ? new Date : new Date(dateInt);
  if (typeof dateInt === "string") {
    return date;
  } else {
    const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + offset * 60000)
    return offsetDate;
  }
}


var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export function inWords(num) {
  if ((num = num.toString()).length > 9) return 'overflow';
  var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
  return str;
}
