import {createSlice} from "@reduxjs/toolkit";

const initialCompanyProfilesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  loadData:null,
  fileName:[],
  companyProfileForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const companyProfilesSlice = createSlice({
  name: "companyProfiles",
  initialState: initialCompanyProfilesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    companyProfilesLoadedData: (state, action) => {
      state.error = null;
      state.loadData = action.payload.loadData;
      state.actionsLoading = false;
    },
    // getCompanyProfileById
    companyProfileFetched: (state, action) => {
      state.actionsLoading = false;
      state.companyProfileForEdit = action.payload.companyProfileForEdit.data;
      state.error = null;
    },
    attachmentDeleted: (state, action) => {
      state.error = null;
      state.fileName = state.fileName.filter(el => el.idLegalDocumentType !== action.payload.idLegalDocumentType.idLegalDocumentType);
      state.actionsLoading = false;
    },
    allAttachmentDeleted: (state, action) => {
      state.error = null;
      state.fileName = [];
      state.actionsLoading = false;
    },
    attachmentUploaded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.fileName.push(action.payload.fileName)
    },
    // updateCompanyProfile
    companyProfileUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.companyProfile.id) {
      //     return action.payload.companyProfile;
      //   }
      //   return entity;
      // });
    },
    // companyProfileAdded
    companyProfileAdded: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  }
});
