import {createSlice} from "@reduxjs/toolkit";

const initialResourcesSubTypesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  resourcesSubTypeForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const resourcesSubTypesSlice = createSlice({
  name: "resourcesSubTypes",
  initialState: initialResourcesSubTypesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    configurationLoadedData: (state, action) => {
      state.error = null;
      state.loadData = action.payload.loadData;
      state.actionsLoading = false;
    },
    // getResourcesSubTypeById
    resourcesSubTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.resourcesSubTypeForEdit =  action.payload.resourcesSubTypeForEdit && action.payload.resourcesSubTypeForEdit.data;
      state.error = null;
    },
    // findResourcesSubTypes
    resourcesSubTypesFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = list;
      state.totalCount = totalCount;
    },
    // createResourcesSubType
    resourcesSubTypeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.resourcesSubType);
    },
    // updateResourcesSubType
    resourcesSubTypeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.resourcesSubType.id) {
          return action.payload.resourcesSubType;
        }
        return entity;
      });
    },
    // deleteResourcesSubType
    resourcesSubTypeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteResourcesSubTypes
    resourcesSubTypesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // resourcesSubTypesUpdateState
    resourcesSubTypesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
