export default [
  {
    id: "8b19e330-2fd1-892e-78d1-9c8aa7f357fe",
    idClient: "008bf26e-d0f5-22d2-206f-3c7c48227e9e",
    clientName: "Safaricom",
    resourceType: "mlewjftsbx",
    totalCount: 11,
    availableDate: "5/31/2001 10:13:00 AM",
    reason: [
      "cniwjberlk",
      "zbatvpudoy"
    ]
  },
  {
    id: "13708335-9b16-57d8-4eb0-825df1ba2f0e",
    idClient: "7482f1af-6ff7-25e7-6d2e-a8262f53863b",
    clientName: "Telkom Kenya",
    resourceType: "voyavsiwxy",
    totalCount: 139,
    availableDate: "1/4/2001 7:43:49 AM",
    reason: [
      "hbhqxilxux",
      "bpjjwbxeru"
    ]
  },
  {
    id: "4a9e324c-ab08-d706-9ddf-9c25e92085e1",
    idClient: "56793845-e15c-9930-8d81-cd5a356c6d9f",
    clientName: "Essar Telecom Kenya",
    resourceType: "uchbxmewrx",
    totalCount: 116,
    availableDate: "7/23/2014 4:35:17 AM",
    reason: [
      "tuwbrjcwlf",
      "frbrdghhtq"
    ]
  }
];
