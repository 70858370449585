import React, { useState ,useEffect} from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link, Redirect } from "react-router-dom";

function EmailSent(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);


  return (
    <>
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        
        <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                  <img
                    alt="Logo"
                    className="max-h-70px"
                    src={toAbsoluteUrl("/media/logos/logo-dark.png")}
                  />
                </Link>
        </div>
        {/* end::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgot password confirmation</h3>
            <div className="text-muted font-weight-bold">
            Please check your email to reset your password.
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(EmailSent));
