import axios from "axios";
import { sendRequestDotNet } from "../../../../_components/Axios";
// export const LOGIN_URL = "Account/Login";
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

// export const ME_URL = "api/me";

export function login( username, password,idProvider,socialToken) {
  return sendRequestDotNet("User/Login", { username, password,idProvider,socialToken });
  // return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(userName) {
  // return axios.post(REQUEST_PASSWORD_URL, { email });
  return sendRequestDotNet("User/ForgetPassword", { userName});
}
export function changePassword(userName) {
  // return axios.post(REQUEST_PASSWORD_URL, { email });
  return sendRequestDotNet("User/ChangePassword",  userName);
}

export function resetPassword(password,confirmPassword,idUser) {
  // return axios.post(REQUEST_PASSWORD_URL, { email });
  return sendRequestDotNet("User/ResetPassword", { password,confirmPassword,idUser});
}
export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
