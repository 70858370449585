/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

import {
  Dashboard,
  ClientManagement,
  ApplicationManagementCAK,
  ResourceManagementStockingResources,
  ResourceManagementReclaimingResources,
  ResourceManagementPendingRecallingResources,
  InterfaceReport,
  ConfigurationResourcesType,
  ConfigurationResourcesSubtype,
  ConfigurationClientPermission,
  WebRole,
  WebUsers,
  Settings,
  CompanyProfile,
  ApplicationManagementClient,
  ResourceManagementRecallingResources,
} from "../../../../../app/RoutesId";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  const APP_TYPE = process.env.REACT_APP_TYPE;
  // console.log(process.env.REACT_APP_ADMIN)
  // console.log(APP_TYPE)

  const { user } = useSelector(
    (state) => ({
      user: state.auth,
    }),
    shallowEqual
  );
  //console.log(">>>>>>>>>>>>>>",user,"<<<<<<<<<<<<<<<<<");

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {user.sections &&
          user.sections.findIndex(
            (item) =>
              item.id == Dashboard &&
              item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
          ) != -1 && (
            <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  />
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {user.sections &&
          user.sections.findIndex(
            (item) =>
              item.id == CompanyProfile &&
              item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
          ) != -1 && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/company-profile",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/company-profile">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  />
                </span>
                <span className="menu-text">Company Profile</span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {user.sections &&
          user.sections.findIndex(
            (item) =>
              item.id == ClientManagement &&
              item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
          ) != -1 && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/client-management",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/client-management">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Client Management</span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {user.sections &&
          user.sections.findIndex(
            (item) =>
              (user.user.utype == 1 &&
                item.id == ApplicationManagementCAK &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                  -1) ||
              (user.user.utype == 2 &&
                item.id == ApplicationManagementClient &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                  -1)
          ) != -1 && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/application-management",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/application-management">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Group-folders.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Application Management</span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/client-portal/resource-management", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/client-portal/resource-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Adress-book1.svg")} />
            </span>
            <span className="menu-text">Resource Management</span>
          </NavLink>
        </li> */}

        {user.sections &&
          user.sections.findIndex(
            (item) =>
              (user.user.utype == 1 &&
                item.id == ResourceManagementPendingRecallingResources &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                  -1) ||
              (user.user.utype == 2 &&
                item.id == ResourceManagementReclaimingResources &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                  -1) ||
              (user.user.utype == 2 &&
                item.id == ResourceManagementStockingResources &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                  -1)
          ) != -1 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/resource-management",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/resource-management"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}
                  />
                </span>
                <span className="menu-text">Resource Management</span>
              </NavLink>
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Resource Management</span>
                    </span>
                  </li>
                  {/*begin::2 Level*/}

                  {user.sections &&
                    user.sections.findIndex(
                      (item) =>
                        item.id == ResourceManagementStockingResources &&
                        item.actions.findIndex(
                          (ac) => ac.toLowerCase() == "view"
                        ) != -1
                    ) != -1 && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/resource-management/stocking-resources"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/resource-management/stocking-resources"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Stocking Resources</span>
                        </NavLink>
                      </li>
                    )}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}

                  {user.sections &&
                    user.sections.findIndex(
                      (item) =>
                        item.id == ResourceManagementReclaimingResources &&
                        item.actions.findIndex(
                          (ac) => ac.toLowerCase() == "view"
                        ) != -1
                    ) != -1 && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/resource-management/reclaiming-resources"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/resource-management/reclaiming-resources"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            Reclaiming Resources
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {user.sections &&
                    user.sections.findIndex(
                      (item) =>
                        item.id ==
                          ResourceManagementPendingRecallingResources &&
                        item.actions.findIndex(
                          (ac) => ac.toLowerCase() == "view"
                        ) != -1
                    ) != -1 && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/resource-management/pending-recalling-resources"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/resource-management/pending-recalling-resources"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">
                            Pending Recalling Resources
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {user.sections &&
          user.sections.findIndex(
            (item) =>
              item.id == ResourceManagementRecallingResources &&
              item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
          ) != -1 && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/resource-management/recalling-resources",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to="/resource-management/recalling-resources"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}
                  />
                </span>
                <span className="menu-text">Resource Management</span>
              </NavLink>
            </li>
          )}
        {/*begin::1 Level*/}

        {user.sections &&
          user.sections.findIndex(
            (item) =>
              item.id == InterfaceReport &&
              item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
          ) != -1 && (
            // <li
            //   className={`menu-item ${getMenuItemActive("/client-portal/interface-report", false)}`}
            //   aria-haspopup="true"
            // >
            //   <NavLink className="menu-link" to="/client-portal/interface-report">
            //     <span className="svg-icon menu-icon">
            //       <SVG src={toAbsoluteUrl(
            //           "/media/svg/icons/General/Clipboard.svg")} />
            //     </span>
            //     <span className="menu-text">Interface Report</span>
            //   </NavLink>
            // </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/interface-report",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/interface-report">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Clipboard.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Interface Report</span>
              </NavLink>
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Interface Report</span>
                    </span>
                  </li>
                  {/*begin::2 Level*/}

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/interface-report/stock-report"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/interface-report/stock-report"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Stock Report</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/interface-report/application-report"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/interface-report/application-report"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Application Report</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
          )}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/client-portal/interface-report", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/client-portal/interface-report">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/General/Settings-2.svg")} />
            </span>
            <span className="menu-text">Configuration</span>
          </NavLink>
        </li> */}
        {user.sections &&
          user.sections.findIndex(
            (item) =>
              (user.user.utype == 1 &&
                item.id == ConfigurationClientPermission &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                  -1) ||
              (user.user.utype == 2 &&
                item.id == ConfigurationResourcesType &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                  -1) ||
              (user.user.utype == 2 &&
                item.id == ConfigurationResourcesSubtype &&
                item.actions.findIndex((ac) => ac.toLowerCase() == "view") !=
                  -1)
          ) != -1 && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/configuration",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/configuration">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}
                  />
                </span>
                <span className="menu-text">Configuration</span>
              </NavLink>
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Configuration</span>
                    </span>
                  </li>
                  {/*begin::2 Level*/}

                  {user.sections &&
                    user.sections.findIndex(
                      (item) =>
                        item.id == ConfigurationResourcesType &&
                        item.actions.findIndex(
                          (ac) => ac.toLowerCase() == "view"
                        ) != -1
                    ) != -1 && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/configuration/resources-type"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/configuration/resources-type"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Resources Type</span>
                        </NavLink>
                      </li>
                    )}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {user.sections &&
                    user.sections.findIndex(
                      (item) =>
                        item.id == ConfigurationResourcesSubtype &&
                        item.actions.findIndex(
                          (ac) => ac.toLowerCase() == "view"
                        ) != -1
                    ) != -1 && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/configuration/resources-subtype"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/configuration/resources-subtype"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Resources Subtype</span>
                        </NavLink>
                      </li>
                    )}
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {user.sections &&
                    user.sections.findIndex(
                      (item) =>
                        item.id == ConfigurationClientPermission &&
                        item.actions.findIndex(
                          (ac) => ac.toLowerCase() == "view"
                        ) != -1
                    ) != -1 && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/configuration/client-permission"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/configuration/client-permission"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Client Permission</span>
                        </NavLink>
                      </li>
                    )}
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {user.sections &&
          user.sections.findIndex(
            (item) =>
              item.id == WebRole &&
              item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
          ) != -1 && (
            <li
              className={`menu-item ${getMenuItemActive("/web-roles", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/web-roles">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/new/Group14638.svg")} />
                </span>
                <span className="menu-text">Web Roles</span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {user.sections &&
          user.sections.findIndex(
            (item) =>
              item.id == WebUsers &&
              item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
          ) != -1 && (
            <li
              className={`menu-item ${getMenuItemActive("/web-users", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/web-users">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Add-user.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Web Users</span>
              </NavLink>
            </li>
          )}
        {/*begin::1 Level*/}
        {user.sections &&
          user.sections.findIndex(
            (item) =>
              item.id == Settings &&
              item.actions.findIndex((ac) => ac.toLowerCase() == "view") != -1
          ) != -1 && (
            <li
              className={`menu-item ${getMenuItemActive("/settings", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/settings">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Shopping/Settings.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Settings</span>
              </NavLink>
            </li>
          )}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
