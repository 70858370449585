import applicationManagementTablePendingMock from "./applicationManagementTablePendingMock";
import applicationManagementTablePaymentPendingMock from "./applicationManagementTablePaymentPendingMock";
import applicationManagementTableRejectedMock from "./applicationManagementTableRejectedMock";
import applicationManagementTableApprovedMock from "./applicationManagementTableApprovedMock";
import MockUtils from "./mock.utils";

export default function mockApplicationManagement(mock) {
  mock.onPost("api/applicationManagements").reply(({ data }) => {
    const { applicationManagement } = JSON.parse(data);
    const {
      firstName = "",
      lastName = "",
      email = "",
      userName = "",
      gender = "Female",
      status = 0,
      dateOfBbirth = "01/01/2019",
      ipAddress = "127.0.0.1",
      type = 1
    } = applicationManagement;

    const id = generateUserId();
    const newApplicationManagement = {
      id,
      firstName,
      lastName,
      email,
      userName,
      gender,
      status,
      dateOfBbirth,
      ipAddress,
      type
    };
    applicationManagementTablePendingMock.push(newApplicationManagement);
    return [200, { applicationManagement: newApplicationManagement }];
  });

  mock.onPost("api/applicationManagements/findPending").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdApplicationManagementsPending = mockUtils.baseFilter(
      applicationManagementTablePendingMock,
      queryParams
    );
    return [200, filterdApplicationManagementsPending];
  });

  mock.onPost("api/clientManagements/findPaymentPending").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdApplicationManagementsPaymentPending = mockUtils.baseFilter(
      applicationManagementTablePaymentPendingMock,
      queryParams
    );
    return [200, filterdApplicationManagementsPaymentPending];
  });
  mock.onPost("api/applicationManagements/findRejected").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdApplicationManagementsRejected = mockUtils.baseFilter(
      applicationManagementTableRejectedMock,
      queryParams
    );
    return [200, filterdApplicationManagementsRejected];
  });

  mock.onPost("api/applicationManagements/findApproved").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdApplicationManagementsApproved = mockUtils.baseFilter(
      applicationManagementTableApprovedMock,
      queryParams
    );
    return [200, filterdApplicationManagementsApproved];
  });

  mock.onPost("api/applicationManagements/deleteApplicationManagements").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = applicationManagementTablePendingMock.findIndex(el => el.id === id);
      if (index > -1) {
        applicationManagementTablePendingMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/applicationManagements/updateStatusForApplicationManagements").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    applicationManagementTablePendingMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/applicationManagements\/\d+/).reply(config => {
    const id = config.url.match(/api\/applicationManagements\/(\d+)/)[1];
    const applicationManagement = applicationManagementTablePendingMock.find(el => el.id === +id);
    if (!applicationManagement) {
      return [400];
    }

    return [200, applicationManagement];
  });

  mock.onPut(/api\/applicationManagements\/\d+/).reply(config => {
    const id = config.url.match(/api\/applicationManagements\/(\d+)/)[1];
    const { applicationManagement } = JSON.parse(config.data);
    const index = applicationManagementTablePendingMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    applicationManagementTablePendingMock[index] = { ...applicationManagement };
    return [200];
  });

  mock.onDelete(/api\/applicationManagements\/\d+/).reply(config => {
    const id = config.url.match(/api\/applicationManagements\/(\d+)/)[1];
    const index = applicationManagementTablePendingMock.findIndex(el => el.id === +id);
    applicationManagementTablePendingMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = applicationManagementTablePendingMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
