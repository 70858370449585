import {createSlice} from "@reduxjs/toolkit";

const initialWebRolesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  webRoleForEdit: undefined,
  lastError: null,
  loadData:undefined,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const webRolesSlice = createSlice({
  name: "webRoles",
  initialState: initialWebRolesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    webRoleLoadData: (state, action) => {
      state.error = null;
      state.loadData = action.payload.loadData;
      state.actionsLoading = false;
    },
    // getWebRoleById
    webRoleFetched: (state, action) => {
      state.actionsLoading = false;
      state.webRoleForEdit = action.payload.webRoleForEdit && action.payload.webRoleForEdit.data;
      state.error = null;
    },
    // findWebRoles
    webRolesFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = list;
      state.totalCount = totalCount;
    },
    // createWebRole
    webRoleCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.webRole);
    },
    // updateWebRole
    webRoleUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.webRole.id) {
      //     return action.payload.webRole;
      //   }
      //   return entity;
      // });
    },
    // deleteWebRole
    webRoleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    //  state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteWebRoles
    webRolesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // webRolesUpdateState
    webRolesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
