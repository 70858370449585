import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import {
  KTCookie
} from "../../../../_metronic/_assets/js/components/cookie";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
};

const initialAuthState = {
  sections: undefined,
  statusCode: undefined,
  user: undefined,
  // authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth", whitelist: ["sections","user","authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        //console.log(action.payload)
        // const { authToken } = action.payload;
        const { sections,statusCode,user } = action.payload;
        
        KTCookie.setCookie("uu", user.utype);
        KTCookie.setCookie("tt", user.token);
        KTCookie.setCookie("cc", user.idClient);
        KTCookie.setCookie("AD", false);

        return { authToken:user.token, user: user,sections:sections };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      // case actionTypes.UserLoaded: {
      //   const { user } = action.payload;
      //   return { ...state, user };
      // }

      case actionTypes.SetUser: {
        const { sections,statusCode,user  } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (sections, statusCode,user) => ({ type: actionTypes.Login, payload: { sections,statusCode,user } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (sections, statusCode,user) => ({
    type: actionTypes.UserRequested,
    payload: { sections, statusCode,user },
  }),
  fulfillUser: (sections, statusCode,user) => ({ type: actionTypes.UserLoaded, payload: { sections, statusCode,user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // const { data: user } = yield getUserByToken();

    const user = {};
    yield put(actions.fulfillUser(user));
  });
}
