export default [
  {
    id: "a3889b25-3ef8-712e-dad8-68fcbe60a676",
    name: "Essar Telecom Kenya",
    clientType: "Application Service Providers",
    attachments: [
      {
        id: "clqipoyaqw",
        url: "https://myip/jvsEedbtTnRTVUj.com",
        idLegalDocumentType: "wpznhynhgr"
      },
      {
        id: "ixkjkyjgfe",
        url: "https://myip/rTQmuSjnbeNvRoR.com",
        idLegalDocumentType: "pckfakexee"
      }
    ],
    contactPerson: "Shane Lemke",
    contactPhone: "312.291.8544 x5160",
    contactEmail: "UOViBZeJQn@testnms.com"
  },
  {
    id: "5ce754ce-e077-6ccc-ddd7-c997710b0ed4",
    name: "Airtel Networks Kenya",
    clientType: "Application Service Providers",
    attachments: [
      {
        id: "vqmqhzsilo",
        url: "https://myip/ohMhDEDUdDHFSIZ.com",
        idLegalDocumentType: "okbpvufxtr"
      },
      {
        id: "orowsarbvp",
        url: "https://myip/eZictKoutlicZFN.com",
        idLegalDocumentType: "cpqiywcjpg"
      }
    ],
    contactPerson: "Connie Reichert",
    contactPhone: "1-688-887-8843",
    contactEmail: "VyzkNwpuTq@testnms.com"
  }
];
