export default [
  {
    reasons: [
      "wcgsxrxjmy",
      "mhwsmwcdel"
    ],
    id: "1be35a71-406b-d707-2612-0547a5895652",
    name: "Airtel Networks Kenya",
    clientType: "Content Service Providers",
    attachments: [
      {
        id: "ulblqdpful",
        url: "https://myip/iCJGGxGInwxoxut.com",
        idLegalDocumentType: "nhxibprygl"
      },
      {
        id: "jnzzxzwfoq",
        url: "https://myip/jNyCGxRyqESsmGS.com",
        idLegalDocumentType: "vjqwsmjwkf"
      }
    ],
    contactPerson:"Roy Rowe",
    contactPhone: "625-395-4986 x00536",
    contactEmail: "eMPkDlOccf@testnms.com"
  },
  {
    reasons: [
      "tnjkldahkh",
      "lmanlqkuha"
    ],
    id: "58797062-02b5-80ef-0317-eda88dd0fee2",
    name: "Airtel Networks Kenya",
    clientType: "Content Service Providers",
    attachments: [
      {
        id: "tkpuoextes",
        url: "https://myip/XkPxJCqLZMJNHgo.com",
        idLegalDocumentType: "rixplzhiec"
      },
      {
        id: "uyudgspnfj",
        url: "https://myip/aBZNxShcKbrPSJR.com",
        idLegalDocumentType: "lzrcpghprj"
      }
    ],
    contactPerson: "Deanna Botsford",
    contactPhone: "698-606-1091",
    contactEmail: "kdaPSMPpcc@testnms.com"
  }
];
