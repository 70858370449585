import clientManagementTablePendingMock from "./clientManagementTablePendingMock";
import clientManagementTableRejectedMock from "./clientManagementTableRejectedMock";
import clientManagementTableApprovedMock from "./clientManagementTableApprovedMock";
import MockUtils from "./mock.utils";

export default function mockClientManagement(mock) {
  mock.onPost("api/clientManagements").reply(({ data }) => {
    const { clientManagement } = JSON.parse(data);
    const {
      firstName = "",
      lastName = "",
      email = "",
      userName = "",
      gender = "Female",
      status = 0,
      dateOfBbirth = "01/01/2019",
      ipAddress = "127.0.0.1",
      type = 1
    } = clientManagement;

    const id = generateUserId();
    const newClientManagement = {
      id,
      firstName,
      lastName,
      email,
      userName,
      gender,
      status,
      dateOfBbirth,
      ipAddress,
      type
    };
    clientManagementTablePendingMock.push(newClientManagement);
    return [200, { clientManagement: newClientManagement }];
  });

  mock.onPost("api/clientManagements/findPending").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdClientManagementsPending = mockUtils.baseFilter(
      clientManagementTablePendingMock,
      queryParams
    );
    return [200, filterdClientManagementsPending];
  });
  

  mock.onPost("api/clientManagements/findRejected").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdClientManagementsRejected = mockUtils.baseFilter(
      clientManagementTableRejectedMock,
      queryParams
    );
    return [200, filterdClientManagementsRejected];
  });

  mock.onPost("api/clientManagements/findApproved").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdClientManagementsApproved = mockUtils.baseFilter(
      clientManagementTableApprovedMock,
      queryParams
    );
    return [200, filterdClientManagementsApproved];
  });

  mock.onPost("api/clientManagements/deleteClientManagements").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = clientManagementTablePendingMock.findIndex(el => el.id === id);
      if (index > -1) {
        clientManagementTablePendingMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/clientManagements/updateStatusForClientManagements").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    clientManagementTablePendingMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/clientManagements\/\d+/).reply(config => {
    const id = config.url.match(/api\/clientManagements\/(\d+)/)[1];
    const clientManagement = clientManagementTablePendingMock.find(el => el.id === +id);
    if (!clientManagement) {
      return [400];
    }

    return [200, clientManagement];
  });

  mock.onPut(/api\/clientManagements\/\d+/).reply(config => {
    const id = config.url.match(/api\/clientManagements\/(\d+)/)[1];
    const { clientManagement } = JSON.parse(config.data);
    const index = clientManagementTablePendingMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    clientManagementTablePendingMock[index] = { ...clientManagement };
    return [200];
  });

  mock.onDelete(/api\/clientManagements\/\d+/).reply(config => {
    const id = config.url.match(/api\/clientManagements\/(\d+)/)[1];
    const index = clientManagementTablePendingMock.findIndex(el => el.id === +id);
    clientManagementTablePendingMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = clientManagementTablePendingMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
