import {createSlice} from "@reduxjs/toolkit";

const initialSettingsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  settingForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSettingById
    settingFetched: (state, action) => {
      state.actionsLoading = false;
      state.settingForEdit = action.payload.settingForEdit.setting;
      state.error = null;
    },
    // updateSetting
    settingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.setting.id) {
      //     return action.payload.setting;
      //   }
      //   return entity;
      // });
    },
  }
});
