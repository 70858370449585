import axios from 'axios';
// import { useSelector, shallowEqual } from "react-redux";
import { KTCookie } from '../_metronic/_assets/js/components/cookie';
import TokenService from "./TokenService";
// const { user } = useSelector((state) => state.auth);
// import { refreshToken } from '../app/modules/Auth/_redux/authCrud';
import * as auth from "../app/modules/Auth/_redux/authRedux";

const instance = axios.create({
	// baseURL: "/cms/api/" //azure
	// baseURL: "/NmsAPI_V3/api/",
	baseURL: "/NmsAPI_V3_JWT/api/",
	// baseURL: "/NmsAPI_realdata_JWT/api/",
	// timeout:300000,
});

var now = new Date()

instance.defaults.headers.post['Accept'] = "application/json";
// instance.defaults.headers.post['IdLanguage'] = 1;//
instance.defaults.headers.post['offset'] = -now.getTimezoneOffset();//
//instance.defaults.headers.post['Token'] = "6c9162ad-f625-47c0-b06e-c3a594081b94";
// instance.defaults.headers.post['Authorization'] = "123";
// instance.defaults.headers.Authorization = "Bearer "+KTCookie.getCookie("jwt");
instance.defaults.headers["Access-Control-Allow-Origin"]="*"
instance.defaults.headers["Access-Control-Allow-Headers"]="Origin, X-Requested-With, Content-Type, Accept"

// instance.defaults.headers.post['content-type'] = "application/json";

///// only those interceptors will work, the ones in index.js won't /////
instance.interceptors.request.use(request => {
	// console.log(request);
	//Edit request config
	return request;
}, error => {
	console.log(error); // error.message
	return Promise.reject(error);
});

instance.interceptors.response.use(
	(res) => {
	  return res;
	},
	async (err) => {
	  const originalConfig = err.config;
	  if (originalConfig.url !== "/auth/login" && err.response) {
		// Access Token was expired
		if (err.response.status === 401 && !originalConfig._retry) {
		  originalConfig._retry = true;
		  try {
			// const rs = await refreshToken();//
			let data = JSON.parse(localStorage.getItem('user'));
			let data2 = JSON.parse(localStorage.getItem('persist:v713-demo1-auth'));
			// console.log(data)
			// console.log(data2)
			localStorage.setItem('persist:v713-demo1-auth', JSON.stringify(data2));
			// if(rs.data.statusCode.code===0){//
							// const { accessToken } = rs.data;
			// console.log(rs.data.user.token)
			//KTCookie.setCookie("jwt",rs.data.user.token)
			// auth.actions.refreshToken(rs.data.user.token,rs.data.roles,rs.data.user,rs.data.seasons)//
			// instance.defaults.headers.Authorization = "Bearer "+KTCookie.getCookie("jwt");
			// console.log(KTCookie.getCookie("jwt"))
			
			// TokenService.updateLocalAccessToken(accessToken);
			return instance(originalConfig);
			// }//

		  } catch (_error) {
			return Promise.reject(_error);
		  }
		}
	  }
	  return Promise.reject(err);
	}
	);

// instance.interceptors.response.use(response => {
// 	// console.log(response);
// 	//Edit response config
// 	return response;
// }, error => {
// 	console.log(error); // error.message
// 	return Promise.reject(error);
// });

export default instance;