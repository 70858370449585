export default [
  {
    id: "07914f19-0fb7-2ac4-6ca6-29be5aec9a8f",
    idClient: "d246f49e-cc2e-d811-14ff-6433054a5497",
    clientName: "Essar Telecom Kenya",
    resourceType: "zdchqbnjso",
    totalCount: 268,
    availableDate: "10/16/1997 5:30:11 AM"
  },
  {
    id: "ab5ad5d3-0345-960d-a097-073f7a02ca23",
    idClient: "66fbcbc3-243e-86a1-9f53-2f277f32c95d",
    clientName: "Safaricom",
    resourceType: "cordgepspt",
    totalCount: 60,
    availableDate: "11/19/2001 2:14:25 AM"
  },
  {
    id: "90079701-13b6-ddf3-37c4-b4cfd02c8bb5",
    idClient: "a47f5e6f-dcdd-8c91-5023-3f5a25a0f7d7",
    clientName: "Essar Telecom Kenya",
    resourceType: "owohwpwsqx",
    totalCount: 185,
    availableDate: "1/12/2008 12:18:02 PM"
  }
];
