export default [
  {
    id: "a9b4eb37-aab7-1296-27da-5fd354d3df10",
    idClient: "9b29ec52-00ba-7e36-141f-d833616dfe67",
    clientName: "Essar Telecom Kenya",
    resourceType: "fxlkdojlzr",
    totalCount: 119,
    availableDate: "8/1/2019 4:41:57 PM",
    totalAmount: "nredxzldpc"
  },
  {
    id: "da714586-1beb-cd30-6bce-82add188a370",
    idClient: "e6227c66-8f7b-dc19-e20c-13d7ddf9de07",
    clientName: "Airtel Networks Kenya",
    resourceType: "hpnewnsrod",
    totalCount: 8,
    availableDate: "12/15/2015 8:51:02 AM",
    totalAmount: "vrlmxyqwgi"
  },
  {
    id: "86573e56-658e-236e-318c-366145cbf615",
    idClient: "b88f52bf-61fb-babd-4917-8994c2b183f1",
    clientName: "Essar Telecom Kenya",
    resourceType: "nayalkryof",
    totalCount: 35,
    availableDate: "9/1/1996 2:38:07 PM",
    totalAmount: "aicxdjgoow"
  }
];
