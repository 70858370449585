import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {clientManagementsSlice} from "../app/modules/ClientPortal/_redux/clientManagements/clientManagementsSlice";
import {webUsersSlice} from "../app/modules/ClientPortal/_redux/webUsers/webUsersSlice";
import {webRolesSlice} from "../app/modules/ClientPortal/_redux/webRoles/webRolesSlice";
import {stockingResourcesSlice} from "../app/modules/ClientPortal/_redux/stockingResources/stockingResourcesSlice";
import {resourcesTypesSlice} from "../app/modules/ClientPortal/_redux/resourcesTypes/resourcesTypesSlice";
import {resourcesSubTypesSlice} from "../app/modules/ClientPortal/_redux/resourcesSubTypes/resourcesSubTypesSlice";
import {reclaimingResourcesSlice} from "../app/modules/ClientPortal/_redux/reclaimingResources/reclaimingResourcesSlice";
import {permissionsSlice} from "../app/modules/ClientPortal/_redux/permissions/permissionsSlice";
import {applicationManagementsSlice} from "../app/modules/ClientPortal/_redux/applicationManagements/applicationManagementsSlice";
import {pendingRecallingResourcesSlice} from "../app/modules/ClientPortal/_redux/pendingRecallingResources/pendingRecallingResourcesSlice";
import {recallingResourcesSlice} from "../app/modules/ClientPortal/_redux/recallingResources/recallingResourcesSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import {settingsSlice} from "../app/modules/ClientPortal/_redux/settings/settingsSlice";
import {companyProfilesSlice} from "../app/modules/ClientPortal/_redux/companyProfile/companyProfilesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  clientManagements: clientManagementsSlice.reducer,
  applicationManagements: applicationManagementsSlice.reducer,
  settings: settingsSlice.reducer,
  companyProfiles: companyProfilesSlice.reducer,
  pendingRecallingResources: pendingRecallingResourcesSlice.reducer,
  recallingResources: recallingResourcesSlice.reducer,
  webUsers: webUsersSlice.reducer,
  webRoles: webRolesSlice.reducer,
  stockingResources: stockingResourcesSlice.reducer,
  resourcesTypes: resourcesTypesSlice.reducer,
  resourcesSubTypes: resourcesSubTypesSlice.reducer,
  permissions: permissionsSlice.reducer,
  reclaimingResources: reclaimingResourcesSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
