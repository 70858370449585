import {createSlice} from "@reduxjs/toolkit";

const initialReclaimingResourcesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  loadData: null,
  reclaimingResourceForEdit: undefined,
  listApplications: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const reclaimingResourcesSlice = createSlice({
  name: "reclaimingResources",
  initialState: initialReclaimingResourcesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reclaimingResourcesLoadedData: (state, action) => {
      state.error = null;
      state.loadData = action.payload.loadData;
      state.actionsLoading = false;
    },
    // getReclaimingResourceById
    reclaimingResourceFetched: (state, action) => {
      state.actionsLoading = false;
      state.reclaimingResourceForEdit = action.payload.reclaimingResourceForEdit;
      state.error = null;
    },
    listApplicationsFetched: (state, action) => {
      state.actionsLoading = false;
      state.listApplications = action.payload.list;
      state.listLoading = false;
      state.error = null;
    },
    listApplicationsReclaimed: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // findReclaimingResources
    reclaimingResourcesFetched: (state, action) => {
      const { totalCount, list } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = list;
      state.totalCount = totalCount;
    },
    // createReclaimingResource
    reclaimingResourceCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.reclaimingResource);
    },
    // createReclaimingResource
    listApplicationFetched: (state, action) => {
      state.actionsLoading = false;
      state.listApplications = action.payload.listApplications
      state.error = null;
    },
    // updateReclaimingResource
    reclaimingResourceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.reclaimingResource.id) {
          return action.payload.reclaimingResource;
        }
        return entity;
      });
    },
    // deleteReclaimingResource
    reclaimingResourceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteReclaimingResources
    reclaimingResourcesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // reclaimingResourcesUpdateState
    reclaimingResourcesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
