import * as requestFromServer from "./companyProfilesCrud";
import {companyProfilesSlice, callTypes} from "./companyProfilesSlice";
import { toastMe } from "../../../../../_components/Axios";

const {actions} = companyProfilesSlice;


export const companyProfilesLoadData = () => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .companyProfilesLoadData()
    .then(response => {
      const {loadData} = response.data;
      dispatch(actions.companyProfilesLoadedData({ loadData: response.data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const uploadAttachment = file => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .uploadAttachment(file.data)
    .then(response => {
      const { filename } = response.data;
      var obj = {
        filename :filename,
        idLegalDocumentType:file.idLegalDocumentType
      }
      if(response.error) toastMe(true, response.message, "warning"); 
      else toastMe(true, response.data.statusCode.message, "success"); 
      dispatch(actions.attachmentUploaded({ fileName:obj }));
    })
    .catch(error => {
      error.clientMessage = "Can't create companyProfile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchCompanyProfile = () => dispatch => {
  // if (!id) {
  //   return dispatch(actions.companyProfileFetched({ companyProfileForEdit: undefined }));
  // }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCompanyProfileById()
    .then(response => {
      const companyProfile = response.data;
      dispatch(actions.companyProfileFetched({ companyProfileForEdit: companyProfile }));
    })
    .catch(error => {
      error.clientMessage = "Can't find companyProfile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAttachment = id => dispatch => {
  dispatch(actions.attachmentDeleted({ idLegalDocumentType: id }));

};
export const deleteAllAttachment = () => dispatch => {
  dispatch(actions.allAttachmentDeleted());

};

export const updateCompanyProfile = companyProfile => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCompanyProfile(companyProfile)
    .then((response) => {
      if(response.error) toastMe(true, response.message, "warning"); 
      else toastMe(true, response.data.statusCode.message, "success"); 
      dispatch(actions.companyProfileUpdated({ companyProfile }));
    })
    .catch(error => {
      error.clientMessage = "Can't update companyProfile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addCompanyProfile = companyProfile => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addCompanyProfile(companyProfile)
    .then((response) => {
      if(response.error) toastMe(true, response.message, "warning"); 
      else if(response.data.statusCode.code==0) toastMe(true, response.data.statusCode.message, "success"); 
      else toastMe(true, response.data.statusCode.message, "warning"); 
      dispatch(actions.companyProfileAdded({ companyProfile }));
      return response.data;
    })
    .catch(error => {
      error.clientMessage = "Can't update companyProfile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      // return response;
    });
};