import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../layout";
import { ApplicationReportDashboard } from "./ApplicationReportDashboard";

export function DashboardApplication() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            demo: objectPath.get(
                uiService.config,
                "demo"
            )};
    }, [uiService]);
    return <>
    <ApplicationReportDashboard/>
    </>;
}
