import React from "react";
import {PaginationRangeLinks} from "./PaginationRangeLinks";
import {PaginationRangeToolbar} from "./PaginationRangeToolbar";

export function PaginationRange(props) {
  const { children, isLoading, paginationProps,PagePerSize } = props;
  return (
    <>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationRangeLinks paginationProps={paginationProps} />
        <PaginationRangeToolbar
        PagePerSize={PagePerSize}
          isLoading={isLoading}
          paginationProps={paginationProps}
        />
      </div>
    </>
  );
}
